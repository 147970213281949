





































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import MontageService from "@/views/offer/montage.service";
import {GascadeStation} from '@/models/gascade-station';
import {Montage} from "@/models/Montage";
import {Customer} from "@/models/customer";
import {namespace} from "vuex-class";

export enum CertainCustomer {
  Gascade = "GASCADE Gastransport GmbH",
}

const CustomerStore = namespace('customer');

@Component({
  components: {
    MontageTableRowComponent: () =>
        import(
            '@/views/offer/MontageTableRow.vue'
            )
  }
})
export default class MontageTableComponent extends Vue {

  @CustomerStore.Getter('selectedCustomer')
  public customer!: Customer;

  @Watch('montage', {deep: true})
  public sum() {
    this.$emit('montage', this.montage)
  }

  private montageService: any;

  private montageTableHeaders: any = [
    {
      text: 'Pos.',
      align: 'center',
      sortable: false,
      value: 'pos',
    },
    {
      text: 'Benennung',
      align: 'left',
      sortable: false,
      value: 'benennung',
    },
    {
      text: 'Stk.',
      align: 'center',
      sortable: false,
      value: 'stueck',
    },
    {
      text: 'Preis/Stk.',
      align: 'center',
      sortable: false,
      value: 'priceStk',
    },
    {
      text: 'Preis/Pos.',
      align: 'center',
      sortable: false,
      value: 'pricePos',
    },
  ]

  public montage: Montage = {}

  public created() {

    this.switchGascade =  CertainCustomer.Gascade === this.customer.name;

    this.montageService = MontageService(this.switchGascade);
    this.montage = new Montage({
      mitarbeiterzahl: 1,
      fahrzeugezahl: 1,
      halbtagspauschale: 2,
      tagespauschale: 10,
      ueberstunden: 5,
      uebernachtung: 8,
      reisekostenpauschale: 1,
      entfernung: 600,
      inland: true,
      sumMontage: 0
    })
    // console.log( typeof this.montage)

    this.$emit('montage', this.montage);
  }

  private montageEntries: any = []

  @Watch('montage', {deep: true})
  updateMontageEntries() {
    this.montageEntries = [
      {
        benennung: 'Halbtagespauschale',
        stueck: this.montage.halbtagspauschale,
        priceStk: this.montageService.getHalbtagesPauschale(this.montage),
        pricePos: this.montageService.halbtagesPauschale_summe(this.montage)
      },
      {
        benennung: 'Tagespauschale',
        stueck: this.montage.tagespauschale,
        priceStk: this.montageService.getTagesPauschale(this.montage),
        pricePos: this.montageService.tagesPauschale_summe(this.montage)
      },
      {
        benennung: 'Überstunden',
        stueck: this.montage.ueberstunden,
        priceStk: this.montageService.UEBERSTUNDE,
        pricePos: this.montageService.ueberstunden_summe(this.montage)
      },
        {
        benennung: 'Übernachtung',
        stueck: this.montage.uebernachtung,
        priceStk: this.montageService.UEBERNACHTUNGSPAUSCHALE,
        pricePos: this.montageService.uebernachtung_summe(this.montage)
      },
        {
        benennung: 'Reisekostenpauschale',
        stueck: this.montage.reisekostenpauschale,
        priceStk: this.montageService.getEntfernung(this.montage),
        pricePos: this.montageService.getEntfernung_summe(this.montage)
      }
    ]
  }

  private switchGascade: boolean = false;

  private GASCADE: GascadeStation[] = [
    {
      name: 'VS-Bunde',
      distance: 173
    },
    {
      name: 'NKP-Deutschneudorf',
      distance: 484
    },
    {
      name: 'VS-Eischleben',
      distance: 377
    },
    {
      name: 'VS-Lippe',
      distance: 197
    },
    {
      name: 'AST-Lubmin I',
      distance: 369
    },
    {
      name: 'AST-Lubmin II',
      distance: 368
    },
    {
      name: 'VS-Mallnow',
      distance: 445
    },
    {
      name: 'VS-Olbernhau',
      distance: 480
    },
    {
      name: 'VS-Radeland I',
      distance: 412
    },
    {
      name: 'VS-Radeland II',
      distance: 412
    },
    {
      name: 'VS-Reckrod',
      distance: 347
    },
    {
      name: 'VS-Rehden',
      distance: 105
    },
    {
      name: 'VS-Rückersdorf',
      distance: 437
    },
    {
      name: 'VS-Weisweiler',
      distance: 394
    },
    {
      name: 'GDRM-Heidenau',
      distance: 43
    },
    {
      name: 'GDRM-Drohne',
      distance: 150
    },
    {
      name: 'BGEA-Nonnendorf',
      distance: 467
    },
    {
      name: 'BGEA-Fuchswinkel',
      distance: 36
    },
    {
      name: 'BGEA-Palm/Wörth',
      distance: 585
    }
  ];

  private selectedGascade: GascadeStation = this.GASCADE[0];

  @Watch('switchGascade')
  public gascadeStations() {
    if (!this.switchGascade) {
      this.montage.entfernung = 600;
    } else {
      this.stationChanged()
    }
  }

  public stationChanged() {
    this.montage.entfernung = this.selectedGascade.distance;
  }

}

